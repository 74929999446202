import { Scope } from '@unform/core'
import { t } from 'i18next'

import { Badge } from '~/components/UI/Badge'
import { FormGroup, Label, Radio } from '~/components/unform'

import PlacementsCheckboxesList from '../../../PlacementsRadioList'

const Placements: React.FC<PlacementsFieldsetProps> = ({
  targeting,
  adType
}) => {
  const optionsTypes: RadioOptionProps[] = [
    {
      id: `placesCheck_all`,
      value: 'all',
      label: (
        <div className="d-flex align-items-center gap-2">
          <strong>
            {t('rm:campaign.formV2.elements.placements.standardBroadcast')}
          </strong>{' '}
          <Badge template="success" size="tiny">
            {t('rm:recommended')}
          </Badge>
        </div>
      ),
      description: t(
        'rm:campaign.formV2.elements.placements.optimizeAdsDescription'
      )
    },
    {
      id: `placesCheck_selected`,
      value: 'selected',
      label: (
        <div className="d-flex align-items-center gap-2">
          <strong>
            {t('rm:campaign.formV2.elements.placements.restrictPlacements')}
          </strong>{' '}
          <Badge template="warning" size="tiny">
            {t('rm:notRecommended')}
          </Badge>
        </div>
      ),
      description: t(
        'rm:campaign.formV2.elements.placements.manualPlacementSelectionDescription'
      ),
      activeChildren: (
        <PlacementsCheckboxesList targeting={targeting} adType={adType} />
      )
    }
  ]

  return (
    <Scope path="placements">
      <FormGroup>
        <Label
          text="Placement"
          helperText={t(
            'rm:campaign.formV2.elements.placements.determinePlacementsDescription'
          )}
        />

        <Radio options={optionsTypes} name="placementsCheck" required />
      </FormGroup>
    </Scope>
  )
}

export default Placements

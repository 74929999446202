import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { useQuery } from '@tanstack/react-query'

import Table from '~/components/Table'
import { Badge } from '~/components/UI/Badge'
import { FileInput, Label } from '~/components/unform'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import { listSizesBySegmentationService } from '~/modules/retailMedia/services/placements/sizes'
import { getPublisherConfiguration } from '~/modules/retailMedia/store/industry/publisherConfiguration/actions'

import FileToUpload from '../FileToUpload'
import { schema } from './schema'

import * as S from './styles'

export const BannerAddFormContent = ({
  formRef,
  bannersToTable,
  setBannersToTable,
  initialData,
  selectedSegmentation
}: BannerAddFormContentProps) => {
  const { t } = useAPPTranslation()
  /**
   * Lógica de apoio
   */
  const { publisherId } = useMemo(
    () => ({
      publisherId: initialData?.publisher_id
    }),
    [initialData]
  )

  /**
   * Lógica para a funcionalidade principal
   */
  type FilesToUpload = { file: File; id: string }[]
  const [filesToUpload, setFilesToUpload] = useState<FilesToUpload>([])

  const dispatch = useDispatch()

  useEffect(() => {
    if (publisherId) {
      dispatch(getPublisherConfiguration(publisherId))
    }
  }, [dispatch, publisherId])

  const handleDeleteBanner = useCallback(
    (data: DataModalHandle) => {
      setBannersToTable(state => state.filter(item => item.id !== data.id))
    },
    [setBannersToTable]
  )

  const handleSaveBanner = useCallback(
    (data: ItemBannerTable) => {
      const altText = formRef.current.getFieldValue('banner.alt_text')

      setFilesToUpload(prevState =>
        prevState.filter(item => item.id !== data.id)
      )

      setBannersToTable(prevState => {
        return [
          ...prevState,
          { ...data, alt_text: altText, handleDeleteBanner }
        ]
      })
    },
    [formRef, handleDeleteBanner, setBannersToTable]
  )

  /**
   * Handle multiple upload
   */

  const handlePrepareToUploadImage = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const fileList = Array.from(
        event.target.files as FileList,
        (item, index) => ({ file: item, id: `${item.name}_${index}` })
      )

      setFilesToUpload(fileList)
    },
    []
  )

  // const sizes = useMemo(() => data?.configuration?.ads?.banner?.sizes, [data])

  const handleDeleteFileBeforeUpload = (removeId: number) => {
    setFilesToUpload(prevState =>
      prevState.filter(item => item.id !== removeId)
    )
  }

  /**
   * Ad sizes
   */

  const { status: statusAdSize, data: dataAdSize } = useQuery({
    queryKey: ['get-banner-size', publisherId, selectedSegmentation],
    queryFn: async () => {
      const res = await listSizesBySegmentationService({
        publisher_id: publisherId,
        type: 'banner',
        segmentation: selectedSegmentation
      })

      return res.data
    }
  })

  /**
   * Placements
   */

  const options = useMemo(() => {
    if (dataAdSize) {
      const arrayValue: OptionsInterface[] = dataAdSize.map(item => ({
        value: item.name,
        label: item.size,
        width: item.width,
        height: item.height
      }))

      arrayValue.sort((a, b) =>
        a.width !== b.width ? a.width - b.width : a.height - b.height
      )

      return arrayValue
    }
  }, [dataAdSize])

  const getPlacementByDimension = useCallback(
    ({ width, height }: Dimension) => {
      const findPlacement = dataAdSize?.find(
        item => Number(item.width) === width && Number(item.height) === height
      )

      return findPlacement
    },
    [dataAdSize]
  )

  return (
    <>
      <S.WrapperViewAddBanner>
        <Label
          text={t('rm:campaign.create.Banner.label')}
          helperText={t('rm:campaign.create.Banner.helperText')}
        />
      </S.WrapperViewAddBanner>

      <section className="multipleUploadWrapper">
        <FileInput
          name="mediaFile"
          accept="image/*"
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handlePrepareToUploadImage(event)
          }
          isMultiple
          hideIcons
        >
          <div className="pt-3 mt-3 border-top border-tertiary">
            <h4>{t('rm:allowedMediaSizes')}</h4>

            <div className="d-flex gap 2 justify-content-center flex-wrap">
              {options?.map((item, index) => (
                <div key={item.value}>
                  <Badge template="primary">{item.label}</Badge>
                </div>
              ))}
            </div>
          </div>
        </FileInput>

        <S.UploadQueue>
          {filesToUpload?.map((item, index) => (
            <FileToUpload
              data={item}
              key={item.id}
              getPlacementByDimension={getPlacementByDimension}
              onDelete={() => handleDeleteFileBeforeUpload(item.id)}
              onSave={handleSaveBanner}
            />
          ))}
        </S.UploadQueue>
      </section>

      {!!bannersToTable.length && (
        <S.WrapperTabler>
          <Table schema={schema} data={bannersToTable} />
        </S.WrapperTabler>
      )}
    </>
  )
}

import i from 'i18next'

import { useAppInfo } from '~/hooks/useAppInfo'
import useQueryParams from '~/hooks/useQuery'
import Tabs from '~/modules/retailMedia/components/Tabs'

const AdvertiserAudienceTabs: React.FC = () => {
  const { isWhiteLabel } = useAppInfo()

  const query = useQueryParams()
  const id = query.get('rmid')

  const publisherTab = {
    to: '/retail-media/publishers',
    label: i.t('rm:mainTabs.publishers')
  }

  const linkParam = id ? `?rmid=${id}` : ''

  const tabs = [
    !isWhiteLabel && !id ? publisherTab : null,
    {
      to: `/retail-media/campaigns${linkParam}`,
      label: i.t('rm:mainTabs.campaigns')
    },
    {
      to: `/retail-media/ads${linkParam}`,
      label: i.t('rm:mainTabs.ads')
    }
  ].filter(i => !!i)

  return <Tabs tabs={tabs} />
}

export default AdvertiserAudienceTabs

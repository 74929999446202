import i from '~/i18n'
import Tabs from '~/modules/retailMedia/components/Tabs'

const PublisherAudienceTabs: React.FC = () => {
  const tabs = [
    {
      to: `/retail-media/audiences/`,
      label: i.t('rm:audience.home.tab')
    },
    {
      to: `/retail-media/audiences/attributes`,
      label: i.t('rm:audience.attributes.tab')
    },
    {
      to: `/retail-media/audiences/advertisers`,
      label: i.t('rm:audience.advertisers.tab')
    },
    {
      to: `/retail-media/audiences/review`,
      label: i.t('rm:audience.review.tab')
    }
  ]

  return <Tabs tabs={tabs} />
}

export default PublisherAudienceTabs

import { useEffect, useMemo } from 'react'
import { FiAlertCircle, FiCheckCircle } from 'react-icons/fi'

import { IconCircle } from '~/components/Icon/Circle'
import Panel from '~/components/Panel'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'

import { BannerAddFormContent } from './BannerAddFormContent'

export const Banner = ({
  handleSectionValidity,
  bannersToTable,
  ...rest
}: BannerPanelProps) => {
  const { t } = useAPPTranslation()

  /**
   * Handle section is valid
   * * Refactor
   */

  const sectionIsValid = useMemo(
    () => !!bannersToTable?.length,
    [bannersToTable]
  )

  useEffect(
    () => handleSectionValidity?.(sectionIsValid),
    [handleSectionValidity, sectionIsValid]
  )

  return (
    <Panel
      iconLeft={
        sectionIsValid ? (
          <IconCircle icon={FiCheckCircle} template="successFill" />
        ) : (
          <IconCircle icon={FiAlertCircle} template="warningFill" />
        )
      }
      title={t('rm:campaign.create.Banner.panelTitle')}
      isCollapsible
    >
      <BannerAddFormContent
        bannersToTable={bannersToTable}
        {...(rest as BannerProps)}
      />
    </Panel>
  )
}
